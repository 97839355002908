import styled from "@emotion/styled";
import testimonialsBackground from "../../assets/backgrounds/testimonials/2020.svg";
import { breakpoints, colors, dimensions } from "../../styles/variables";
import DoodleIllustration from "../../assets/doodles/testimonials.inline.svg";
import { Visibility } from "../../types";

export const Wrapper = styled.section<Visibility>`
  
  width: 100%;
  max-width: 1171px;
  height: 2020px;
  margin: 49px auto 0 auto;
  padding: 97px 0 0 18px;
  position: relative;
  background: url(${testimonialsBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  /*
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/

  @media screen and (max-width: ${breakpoints.xl}px) {
    background-position: center 0;
    // height: 1800px;
    width:98%;
    height:auto;
    padding-bottom:100px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    //height: 1580px;
    padding-left: 0;
    background-position: center 0;
    height:auto;
    padding-bottom:100px;
  }

  // @media screen and (max-width: 920px) {
  //   //height: 1620px;
  //   padding-left: 0;
    
  // }

  // @media screen and (max-width: 820px) {
  //   height: 1480px;
  //   padding-left: 0;
  // }

  @media screen and (max-width: ${breakpoints.md}px) {
    background-position: center 0;
    //height: 1370px;
    height:auto;
    padding:100px 0;
  }

  // @media screen and (max-width: 550px) {
  //   background-position: center 0;
  //   height: 1090px;
  //   margin: 0;
  // }

  // @media screen and (max-width: 500px) {
  //   background-position: center 0;
  //   height: 4100px;
  // }
  // @media screen and (max-width: 395px) {
  //   background-position: center 0;
  //   height: 3878px;
  // }
  // @media screen and (max-width: 376px) {
  //   background-position: center 0;
  //   height: 3710px;
  // }
  // @media screen and (max-width: 360px) {
  //   background-position: center 0;
  //   height: 3580px;
  // }
  // @media screen and (max-width: ${breakpoints.xs}px) {
  //   background-position: center 0;
  //   height: 3080px;
  // }
`;

export const Heading = styled.header`
  width: 100%;
  text-align: center;
  padding: 0px 40px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: -20px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size:24px;
  }
`;

interface TestimonialProps {
  isActive: boolean;
  hideSmallScreen: boolean;
}

export const GridMore = styled.div<TestimonialProps>`
  align-items: center;
  display: none;
  ${({ isActive }) =>
    isActive &&
    `
      display: block;
  `}
`;

export const Grid = styled.div`
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  padding-left: 35px;
  padding-right: 35px;
  //margin-top: 20px;

  // @media screen and (max-width: ${breakpoints.xl}px) {
  //   height: 620px;
  //   overflow-y: auto;
  // }
  // @media screen and (max-width: ${breakpoints.lg}px) {
  //   height: 630px;
  //   overflow-y: auto;
  // }
  // @media screen and (max-width: ${breakpoints.md}px) {
  //   height: 680px;
  //   overflow-y: auto;
  // }
  @media screen and (max-width: ${breakpoints.sm}px) {
    // height: 600px;
    // overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    // height: 540px;
    // overflow-y: auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  ${({ hideSmallScreen }) =>
    hideSmallScreen &&
    `
    @media screen and (max-width: 500px) {
      display: none;
    }
  `}
`;

export const Col = styled.div`
  flex: ${(props) => props.size};
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  // background: url("${(props) => props.image}") no-repeat 18px 0;
  // background-size: contain;
  width: ${(props) => props.width};
  // height: ${(props) => props.height};
  place-items: center !important;
  margin-right: 2px;
  margin-top: 2px;
  float: left;

  @media screen and (max-width: 500px) {
    display: grid !important;
    place-items: center !important;
    margin-top: 4px;
    float: none !important;
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

interface ListItemProps {
  isActive: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  list-style: none;
`;

export const Quote = styled.blockquote`
  max-width: 542px;
  margin: 66px auto 0 auto;
  padding: 0;
  transform: translateX(-9px);

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-top: 40px;
    padding: 0 20px;
    transform: translateX(0);
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 30px;
  }
`;

export const QuoteContent = styled.p`
  margin: 0;
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.large * 0.8}px;
  }
`;

export const QuoteAuthor = styled.footer`
  margin: 25px 0 0 0;
  font-size: ${dimensions.fontSize.medium}px;
  font-weight: 900;

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.8}px;
  }
`;

export const Authors = styled.ul`
  display: flex;
  max-width: 542px;
  margin: 33px auto 0 auto;
  padding: 0;
  transform: translateX(-9px);

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-top: 20px;
    padding: 0 20px;
    transform: translateX(0);
  }
`;

interface AvatarItemProps {
  isActive: boolean;
}

export const AvatarItem = styled.li<AvatarItemProps>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
  margin: 0 8px;
  list-style: none;

  &:hover,
  &:focus-within {
    opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  }

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const AvatarButton = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

export const Doodle = styled(DoodleIllustration)`
  width: 193px;
  height: 104px;
  position: absolute;
  top: -34px;
  left: 14.3%;

  @media screen and (max-width: ${breakpoints.xl}px) {
    transform: scale(0.9);
    top: -34px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    transform: scale(0.8);
    top: -24px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    transform: scale(0.6);
    top: -40px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    transform: scale(0.6);
    top: -30px;
  }
  @media screen and (max-width: 450px) {
    transform: scale(0.6);
    top: 0px;
  }
`;

export const Arrow = styled.img`
  position: absolute;
  bottom: -76px;
  right: 1.7%;
`;

export const TestimonialNav = styled.nav`
  display: flex;
  bottom: -20px;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  z-index: 3;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.xxl}px) {
    margin-left: -12px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 50px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 4px;
  }
`;

interface TestimonialNavDotProps {
  isActive: boolean;
}

export const TestimonialNavDot = styled.button<TestimonialNavDotProps>`
  width: 8px;
  height: 8px;
  margin: 0 16px;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 0;
  cursor: pointer;
  path {
    fill: grey;
  }

  ${({ isActive }) =>
    isActive &&
    `
      //margin: 0 10px;
      transform: scale(2);

      path {
        fill: ${colors.navyBlue};
      }
  `}

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    outline: 0;

    path {
      fill: ${colors.navyBlue};
    }
  }
  @media screen and (max-width: 400px) {
    //display: none;
  }
`;

export const TestimonialNavButton = styled.button`
  margin: 16px 42px 0 42px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: none;
  &:hover,
  &:focus {
    opacity: 0.8;
    outline: 0;
  }

  svg {
    width: 58px;
    height: 44px;
  }
`;
